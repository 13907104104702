import React from 'react';
import { connect } from 'react-redux';
import { BoxedContent, CMSContent, hasCMSContent, DisplayContent, getCMSObject, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';

const RulesPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    const telescript = getCMSObject('data.helpAndFaqs.rules');
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <DisplayContent note='Rules section' isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHTML')}>
                <BoxedContent
                    note='Rules section'
                    hash='rules'
                    isActive={true}
                    hasCollapser={false}
                    isVisible={hasCMSContent('data.helpAndFaqs.rules.contentHeaderText')}
                    title={<TeleScript line={telescript?.contentHeaderText} />}>
                    <CMSContent localStorageObject='webContent' contentPath='data.helpAndFaqs.rules.contentHTML' cmsSourceFirebase={cmsSourceFirebase} />
                </BoxedContent>
            </DisplayContent>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RulesPage);
