import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Container } from 'react-bootstrap';
import { ContentBox, ContentBoxHead, ContentBoxBody, HideFromView, IfLoggedIn, IfLoggedOut, getCMSObject, formatDate } from 'sg-ui-components';
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { errorMessage } from '../errorMessages';
import Menu from '../Menu';
import ClickOutsideComponentEvent from '../../utils/ClickOutsideComponentEvent';
import './dashboard.scss';

/**********************************************************************
 * Component:  PointsDashboard
 * Purpose:    Dashboard component that shows site wide.
 *             If the user is not loggedin - simply displays a login/register button
 *             If user is logged in - displays points information and a sub-menu of
 *             pages user can now navigate too. Submenu is now controlled via the MAC CMS.
 *
 * Props:       user -  user data store
 *              config - config data store
 *              actions - store actions (apis)
 *
 * APIs used:   userActions.getDashboard() - get Dashboard info
 *
 *  Notes:
 */
const PointsDashboard = ({ user, config, actions }) => {
    const [accountNavDisplayed, setAccountNavDisplayed] = useState(false);
    const [error, setError] = useState('');
    const [aboutToExpire, setAboutToExpire] = useState(false);

    //***********************************************************************************
    // Get the dashboard and report any errors
    const getDashboard = async () => {
        await actions.userActions.getDashboard();
        if (user.errors?.code) {
            setError(errorMessage(user.errors.code));
        }
    }; // end getDashboard

    //***********************************************************************************
    // Only call getDashbord for logged in players
    useEffect(() => {
        if (user.loggedIn && user.player.actions.length === 0) {
            getDashboard();
        }
    }, []); // end useEffect

    //***********************************************************************************
    // OnClick handler to show/hide the dashboard navigation
    const showAccountNav = async () => {
        setAccountNavDisplayed(!accountNavDisplayed);
    };

    //***********************************************************************************
    // OnMouseLeave handler to hide the dashboard navigation
    const hideAccountNav = async () => {
        setAccountNavDisplayed(false);
    };

    //***********************************************************************************
    // Draw Points Widget - navigates to MyActivity when clicked
    const DrawPoints = () => {
        return (
            <div className='points-dashboard-item'>
                <label htmlFor='drawPoints'>
                    Draw Points
                    <HashLink id='drawPointsTip' to='/my-activity#points-history'>
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' className='dashboard-tooltip' />
                    </HashLink>
                </label>

                <HashLink className='btn theme-btn inset' id='drawPoints' to='/my-activity#points-history'>
                    {user.drawPoints.toLocaleString()}
                </HashLink>
            </div>
        );
    };

    //***********************************************************************************
    // Prize Points Widget - navigates to MyActivity when clicked
    const PrizePoints = () => {
        return (
            <div className='points-dashboard-item'>
                <label htmlFor='PrizePoints'>
                    Prize Points
                    <HashLink id='prizePointsTip' to='/my-activity#points-history'>
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' className='dashboard-tooltip' />
                    </HashLink>
                </label>
                <HashLink className='btn theme-btn inset' id='PrizePoints' to='/my-activity#points-history'>
                    {user.prizePoints.toLocaleString()}
                </HashLink>
            </div>
        );
    };

    //***********************************************************************************
    // Expiring (prize) Points Widget - navigates to feedback form when clicked
    const ExpiringPoints = () => {
        // Check if the points are expiring soon.
        const today = new Date();

        if (parseInt(user.pointsExpiringDate)) {
            const expiringDates = new Date(user.pointsExpiringDate * 1000);
            let daysLeft = Math.abs(expiringDates - today);
            daysLeft = Math.floor(daysLeft / (1000 * 60 * 60 * 24));

            if (daysLeft < config.config.days_until_points_expire) {
                setAboutToExpire(true);
            }
        }

        return (
            <div className={`points-dashboard-item mt-3 mt-md-0 ${aboutToExpire ? 'expiring' : ''}`}>
                <label htmlFor={`ExpiringPoints ${aboutToExpire ? 'expiring' : ''}`}>
                    Expiring Prize Points
                    <HashLink id='expPointsTip' to='/feedback#rules'>
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' className='dashboard-tooltip' />
                    </HashLink>
                </label>
                <HashLink className={`btn theme-btn inset ${aboutToExpire ? 'expiring' : ''}`} id='ExpiringPoints' to='/feedback#rules'>
                    {user.pfpExpiring.toLocaleString()}
                </HashLink>
                {user.pointsExpiringDate && parseInt(user.pointsExpiringDate) > 0 ? (
                    <small className={`theme-text-muted ${aboutToExpire ? 'expiring' : ''}`}>
                        Expires: {user.pointsExpiringDate ? formatDate?.dateTimeWithMeridiem(user.pointsExpiringDate) : ''}
                    </small>
                ) : null}
            </div>
        );
    };

    //***********************************************************************************
    // Login Button Widget - redirects to login page
    const LoginButton = () => {
        return (
            <div className='col-6 text-center'>
                <Link className='logged-out-btn btn theme-btn theme-secondary' id='Login' to='/login'>
                    Log In
                </Link>
            </div>
        );
    };

    //***********************************************************************************
    // Register Button Widget - redirects to registration page
    const RegisterButton = () => {
        return (
            <div className='col-6 text-center'>
                <Link type='button' className='logged-out-btn btn theme-btn theme-danger' id='Register' to='/register'>
                    Sign Up
                </Link>
            </div>
        );
    };

    //***********************************************************************************
    // My Account Navigation widget -  provides user a list of pages they can go when
    // logged in.   Shows/Hides on click.
    const MyAccount = () => {
        const accountMenuRef = useRef(null);
        const dashboardMenu = getCMSObject('dashboardMenu', 'webMenu');
        ClickOutsideComponentEvent(accountMenuRef, hideAccountNav);

        return (
            <div ref={accountMenuRef} className='points-dashboard-item my-account mt-3 mt-md-0'>
                <label className='d-block'>My Account</label>
                <FontAwesomeIcon icon='fa-solid fa-circle-user' className='my-account-img' onClick={showAccountNav} alt='account icon' />
                <Menu menu_json={dashboardMenu} className={`nav flex-column account-nav ${accountNavDisplayed ? 'd-block' : 'd-none'} `} />
            </div>
        );
    };

    return (
        <HideFromView paths={['/login', '/register', '/forgot-password', '/recover-password', '/account-confirm']}>
            <Container>
                <div className='points-dashboard mt-3'>
                    <IfLoggedIn user={user}>
                        <div className='col-lg-10 col-xl-8 mx-auto'>
                            <ContentBox variant='logged-in-dashboard'>
                                <ContentBoxHead>
                                    <span className='welcome-text'>Welcome</span>
                                    <span className='user-name'> {user.player.firstname || 'Player'}!</span>
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    {error ? (
                                        <Alert variant='danger'>
                                            <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                                            <div className='alert-text' dangerouslySetInnerHTML={{ __html: error }} />
                                        </Alert>
                                    ) : null}
                                    <div className='row justify-content-between' onMouseLeave={() => hideAccountNav}>
                                        <DrawPoints />
                                        <PrizePoints />
                                        <ExpiringPoints />
                                        <MyAccount />
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </IfLoggedIn>
                    <IfLoggedOut user={user}>
                        <div className='col-md-8 col-lg-6 col-xl-5 mx-auto'>
                            <ContentBox variant='theme-blue'>
                                <ContentBoxHead>
                                    <span className='welcome-text'>Welcome!</span>
                                </ContentBoxHead>
                                <ContentBoxBody>
                                    <div className='row justify-content-center p-md-3'>
                                        <RegisterButton />
                                        <LoginButton />
                                    </div>
                                </ContentBoxBody>
                            </ContentBox>
                        </div>
                    </IfLoggedOut>
                </div>
            </Container>
        </HideFromView>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PointsDashboard);
