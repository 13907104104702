import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import MyPlayerPrizes from './MyPlayerPrizes';

import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, getCMSObject, TeleScript } from 'sg-ui-components';
import './my-prizes.scss';

const MyPrizes = () => {
    const hash = 'prize-history';

    const telescript = getCMSObject('data.components.teleScripts.myPrizes.jsonBlock');

    return (
        <ContentBox variant='theme-blue' id={hash} show={isHash(hash) ? 'true' : 'false'}>
            <ContentBoxHead>
                <TeleScript line={telescript?.title}>My Prizes</TeleScript>
                <ContentCollapser />
            </ContentBoxHead>
            <ContentBoxBody>
                <MyPlayerPrizes telescript={telescript} />
            </ContentBoxBody>
        </ContentBox>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPrizes);
