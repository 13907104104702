import React from 'react';

/**********************************************************************
 * Component: PromotionDetails
 * Purpose:   Display's the promotion details link based on the sweep type.
 *
 * Props: - sweepsType
 *          sweepstakeId
 *
 */

const PromotionDetails = ({ sweepsType, sweepstakeId }) => {
    const handlePromoDetailsClick = () => {
        sweepsType === 'pfd'
            ? window.open(`/${'points-for-drawing-details'}/${sweepstakeId}`, '_blank')
            : sweepsType === 'second-chance'
            ? window.open(`/${'second-chance-promotion'}?id=${sweepstakeId}`, '_blank')
            : '';
    };

    if (sweepsType === 'pfd') {
        return (
            <button onClick={handlePromoDetailsClick} className='btn p-0 theme-btn-link-underline btn-link'>
                View Points For Drawing Details
            </button>
        );
    } else if (sweepsType === 'second-chance') {
        return (
            <button onClick={handlePromoDetailsClick} className='btn p-0 theme-btn-link-underline btn-link'>
                View Promotion Details
            </button>
        );
    } else {
        return null;
    }
};
export default PromotionDetails;
