import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { PasswordField } from './password_field';
import { errorMessage } from '../errorMessages';
import { ContentBox, ContentBoxHead, ContentBoxBody, CMSContent } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**********************************************************************
 * Component:  StepFourTemplate
 * Purpose:    Allows users to enter SSN for age/user verification
 *             If user fails - allows 2 retries before user gets a "call
 *             customer support message"
 *             Upon Success, takes user to account confirmation check page.
 *
 * Props:       step -  counter of what step we are in the registration process
 *              setStep -  a method to change steps in the process
 *              verifyFields -  fields that we will verify upon completion of form
 *              setVerifyFields - method to set the verification fields
 *              user -  user data store
 *              actions - store actions (apis
 *
 * APIs used:   userActions.verify - verify the SSN4
 *
 * Notes:
 */
const StepFourTemplate = ({ step, setStep, verifyFields, setVerifyFields, user, cmsSourceFirebase, actions }) => {
    const goBack = (e) => {
        e.preventDefault();
        setStep({ step: 2 });
    };

    const initialStepFields = {
        ssn: '',
    };

    //***********************************************************************************
    // SSN 4 Schema for field validation
    const schema = yup.object().shape({
        ssn: yup.string().min(4).max(4).matches('^[0-9]*$').required('The last 4 digits of your Social Security Number is required.'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [localError, setLocalError] = useState('');

    //***********************************************************************************
    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });
    };

    //***********************************************************************************
    // Submits the SSN 4 field for final validation
    const finalSubmit = async (fieldsToPush) => {
        await actions.userActions.verify(fieldsToPush);
    };

    //***********************************************************************************
    // Check to see if SSN4 matches the scema and reports error.   If none - submits
    // for validation.
    const stepSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLocalError('');
        let valid = await schema.isValid(stepFields);
        if (valid) {
            setError('');
            let fieldsToPush = {
                ...verifyFields,
                ssn: stepFields.ssn,
                registrar_id: user.player.registrar_id,
            };
            setVerifyFields(fieldsToPush);
            finalSubmit(fieldsToPush);
        } else {
            await schema.validate().catch(function (err) {
                setLocalError(err.message);
            });
        }
    };

    //***********************************************************************************
    // Callback on validate call.    Reports errors, if there are any.
    const success = async () => {
        if (!user.verifySuccess && step.step == 4) {
            if (user.errors) {
                setError(user.errors);
            }
        } else {
            if (user.verifySuccess) {
                setError('');
                actions.userActions.clearErrors();
            }
        }
    };

    //***********************************************************************************
    // Should not get here if in MUST-VERIFY or NOT VERIFIABLE state,
    // but just in case - disable the submit button.
    const checkSubmitDisabled = (error) => {
        if (['_MUST_MANUAL_VERIFY', '_NOT_VERIFIABLE'].includes(error)) {
            return true;
        }

        return false;
    };

    useEffect(success, [user]);

    useEffect(() => {
        setError('');
        setLocalError('');
    }, []);

    if (user.verifySuccess) {
        return <Redirect to='/await-account-confirmation' />;
    } else if (user.errors == '_MUST_MANUAL_VERIFY') {
        return <Redirect to='/register-verification-error' />;
    } else if (step.step == 4) {
        return (
            <div className='row justify-content-center'>
                <div className='step4 col-12 col-md-10 col-lg-8'>
                    <ContentBox variant='theme-blue mx-2'>
                        <ContentBoxHead>
                            <CMSContent
                                localStorageObject='webContent'
                                contentPath='data.registrationItems.registrationStepFour.contentHeaderText'
                                cmsSourceFirebase={cmsSourceFirebase}
                            />
                        </ContentBoxHead>
                        <ContentBoxBody>
                            <div className='form-step'>
                                <div className='inner-step'>
                                    <div className='col'>
                                        <CMSContent
                                            localStorageObject='webContent'
                                            contentPath='data.registrationItems.registrationStepFour.contentHTML'
                                            cmsSourceFirebase={cmsSourceFirebase}
                                        />
                                    </div>
                                    {localError || error ? (
                                        <Alert variant='danger'>
                                            <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                            {error ? <div className='col' dangerouslySetInnerHTML={{ __html: errorMessage(error) }} /> : null}
                                            {localError ? <div className='col' dangerouslySetInnerHTML={{ __html: localError }} /> : null}
                                        </Alert>
                                    ) : null}
                                    <div className='form-group col'>
                                        <h3>Double Check Your Information</h3>
                                        <table className='table table-borderless'>
                                            <tbody>
                                                <tr>
                                                    <td>First Name</td>
                                                    <td>
                                                        <span>{verifyFields.firstname}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Last Name</td>
                                                    <td>
                                                        <span>{verifyFields.lastname}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Date of Birth</td>
                                                    <td>
                                                        <span>{verifyFields.birthdate}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='align-top'>Home Address</td>
                                                    <td>
                                                        <span>
                                                            {verifyFields.address ? verifyFields.address.address1 : ''}
                                                            <br />
                                                            {verifyFields.address.address2 ? (
                                                                <span>
                                                                    {verifyFields.address.address2}
                                                                    <br />{' '}
                                                                </span>
                                                            ) : null}
                                                            {verifyFields.address ? verifyFields.address.city : ''},{' '}
                                                            {verifyFields.address ? verifyFields.address.state : ''},{' '}
                                                            {verifyFields.address ? verifyFields.address.zip : ''}
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number&nbsp;</td>
                                                    <td>
                                                        <span>{verifyFields.phone}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Link to='#' className='edit-info' onClick={goBack}>
                                            Edit Information
                                        </Link>
                                    </div>
                                    <div className='form-group col'>
                                        <label htmlFor='confirmEmail'>Last 4 SSN</label>
                                        <PasswordField
                                            name='ssn'
                                            id='ssn'
                                            className='password-field col-3 px-0'
                                            value={stepFields.ssn}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='bottom-section text-center mb-3'>
                                <button
                                    type='button'
                                    className='btn theme-btn theme-secondary continue'
                                    onClick={stepSubmit}
                                    disabled={checkSubmitDisabled(error)}>
                                    Submit Registration
                                </button>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const StepFour = connect(mapStateToProps, mapDispatchToProps)(StepFourTemplate);

export { StepFour };
