import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, PasswordExpired } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import UserLockout from '../utils/user_lockout';

const telescriptPasswordExpired = getCMSObject('data.components.teleScripts.PasswordExpired.jsonBlock');

const PasswordExpiredPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <UserLockout disableRedirect='change-password'>
                <PasswordExpired telescript={telescriptPasswordExpired} updateNowLink={'/my-profile'} />
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiredPage);
