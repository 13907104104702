import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import { PasswordField } from '../RegisterForm/password_field';
import { validatePassword, validatePasswordDetailed } from '../../validationRules';
import { ContentBox, ContentBoxHead, ContentBoxBody, isBrowser, ErrorMessage } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RecoverPassword = ({ user, actions }) => {
    //* Retrieve ID from url params
    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {
        passcode: urlParams.get('passcode'),
        mode: urlParams.get('mode'),
    };

    const initialStepFields = {
        password: '',
        confirm: '',
    };

    const schema = yup.object().shape({
        password: validatePassword,
        confirm: yup
            .string()
            .required('Password Confirmation is required')
            .oneOf([yup.ref('password'), null], 'New passwords must match.'),
    });

    const [stepFields, setStepFields] = useState(initialStepFields);
    const [error, setError] = useState('');
    const [passwordStatus, setPasswordStatus] = useState('initial');
    const [localErrors, setLocalErrors] = useState({});

    // if the form field changes, we will change the value in the store and
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trimStart();
        const name = target.name;

        setStepFields({
            ...stepFields,
            [name]: value,
        });

        //* Password inner change
        if (name === 'password') {
            checkPassword(value);
        }
    };

    //  Inline validation for Password to make sure that it matches the password Schema
    const checkPassword = async (password) => {
        const passwordSchema = validatePasswordDetailed;

        const valid = await passwordSchema.isValid(password);

        if (valid) {
            setPasswordStatus('valid');
        } else {
            await passwordSchema.validate(password, { abortEarly: true }).catch(function (err) {
                setPasswordStatus(err.message);
            });
        }
    };

    const passwordStatusForm = (status) => {
        if (status === 'initial') return null;

        return (
            <>
                {status === 'valid' ? (
                    <div className='theme-text-success row align-items-center pl-2 my-2' role='alert'>
                        <FontAwesomeIcon icon='fa-regular fa-circle-check' />
                        <span className='ml-2'>Your password matches the criteria.</span>
                    </div>
                ) : (
                    <div className='theme-text-danger row align-items-center pl-2 my-2' role='alert'>
                        <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                        <span className='ml-2'>{status}</span>
                    </div>
                )}
            </>
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let valid = await schema.isValid(stepFields);

        if (valid) {
            await actions.loadingActions.setLoading({ action: 'recoverPassword' });

            setError('');
            setLocalErrors({});

            await actions.userActions.updateSection({ section: 'recover-password', status: 'initial' });

            const payloadData = {
                password: stepFields.password,
                confirm: stepFields.confirm,
                passcode: queryParams.passcode,
            };

            await actions.userActions.recoverPassword(payloadData);
        } else {
            await schema.validate(stepFields, { abortEarly: false }).catch(function (err) {
                setLocalErrors(getValidationErrors(err));
                setError(err.message);
            });
        }
    };

    const getValidationErrors = (err) => {
        const validationErrors = {};

        err.inner.forEach((error) => {
            if (error.path) {
                validationErrors[error.path] = error.message;
            }
        });

        setLocalErrors(validationErrors);

        return validationErrors;
    };

    //* reset success
    if (user.updateSection.section === 'recover-password' && user.updateSection.status === 'success') {
        return (
            <div className='login-container my-5'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-md-10 col-lg-8'>
                        <ContentBox variant='theme-blue'>
                            <ContentBoxHead>PASSWORD UPDATED</ContentBoxHead>
                            <ContentBoxBody>
                                <div className='form-step'>
                                    <div className='inner-step text-center'>
                                        <img src='/players-club-logo.png' alt='My Lottery Players Club' className='img-fluid mx-auto my-3' />
                                        <p>Congratulations! Your password has been updated.</p>
                                        <Link to='/login' className='btn theme-btn theme-secondary my-3'>
                                            Log In
                                        </Link>
                                    </div>
                                </div>
                            </ContentBoxBody>
                        </ContentBox>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className='login-container theme-form'>
                <div className='my-5' />
                <div className='row justify-content-center my-3'>
                    <div className='col-12 col-md-8'>
                        <ContentBox variant='theme-blue'>
                            <ContentBoxHead>UPDATE PASSWORD</ContentBoxHead>
                            <ContentBoxBody>
                                <div className='form-step'>
                                    <div className='inner-step'>
                                        {error ? (
                                            <Alert variant='danger'>
                                                <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                                                <ul className='alert-text'>
                                                    {Object.values(localErrors).map((err, index) => {
                                                        return <li key={index} dangerouslySetInnerHTML={{ __html: err }} />;
                                                    })}
                                                </ul>
                                            </Alert>
                                        ) : null}

                                        {user.updateSection.section === 'recover-password' && user.updateSection.status === 'error' && (
                                                <ErrorMessage code={user?.updateSection?.code} collection='data.messages.errorMessages.jsonBlock' />
                                        )}

                                        <div className='form-group password-field'>
                                            <label htmlFor='password'>New Password</label>
                                            <small id='passwordHelp' className='form-text text-muted mb-1'>
                                                Your Password must be at least 10 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number
                                                and 1 special character.
                                            </small>
                                            <PasswordField
                                                name='password'
                                                value={stepFields.password}
                                                handleChange={handleChange}
                                                newPassword={true}
                                                placeholder='Password'
                                            />
                                            {passwordStatusForm(passwordStatus)}
                                        </div>

                                        <div className='form-group password-field'>
                                            <label htmlFor='confirmPassword'>Confirm Password</label>
                                            <PasswordField
                                                name='confirm'
                                                value={stepFields.confirm}
                                                autoComplete='off'
                                                handleChange={handleChange}
                                                placeholder='Confirm Password'
                                            />
                                        </div>

                                        <div className='row justify-content-center'>
                                            <div>
                                                <button type='button' className='btn theme-btn theme-secondary btn-block' onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*
                                //? For testing:
                                <div id='for-testing' className='text-center'>
                                    <button onClick={() => actions.userActions.updateSection({ section: 'recover-password', status: 'error-validation' })}>TEST: ERROR: VALIDATION</button>
                                    <button onClick={() => actions.userActions.updateSection({ section: 'recover-password', status: 'error-not-found' })}>TEST: ERROR: INVALID PASSCODE</button>
                                    <button onClick={() => actions.userActions.updateSection({ section: 'recover-password', status: 'success' })}>TEST: SUCCESS</button>
                                </div>
                                */}
                            </ContentBoxBody>
                        </ContentBox>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
