import React from 'react';
import { connect } from 'react-redux';
import { CurrentSweepstakes, CurrentPromotions, getCMSObject, ErrorBoundary, TeleScript, DisplayContent } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';

import Layout from '../Components/Layout';
import { HomeEnterTickets } from '../Components/Enter/HomeEnterTickets';

import promotionConfig from '../promotion_config';
import PointsForDrawingsSection from '../Components/PointsForDrawings/PointsForDrawings';

const HomePage = ({ user, sweep, promotion, config, cmsSourceFirebase, actions, pointsForDrawings, match }) => {
    const telescriptHomeEnterTickets = getCMSObject('data.components.teleScripts.enterTickets.jsonBlock');
    const telescriptHomeSweepstakes = getCMSObject('data.components.teleScripts.homePromotions.jsonBlock');
    const telescriptHomePromotions = getCMSObject('data.components.teleScripts.myRewardsHighlights.jsonBlock');

    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <div className='home-container'>
                <div className='row justify-content-center'>
                    <div className='col-lg-6 entry-box'>
                        <ErrorBoundary fallback={<TeleScript line={telescriptHomeEnterTickets?.fallback} />} details={{ user, config }}>
                            <HomeEnterTickets user={user} />
                        </ErrorBoundary>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <ErrorBoundary fallback={<TeleScript line={telescriptHomeSweepstakes?.fallback} />} details={{ user, config }}>
                            <CurrentSweepstakes
                                {...{ sweep, cmsSourceFirebase, actions }}
                                telescript={telescriptHomeSweepstakes}
                                mode='show-limited-external-url'
                                promotionLink='/promotions#second-chance-promotions'
                                options={{ variant: 'theme-primary' }}
                            />
                        </ErrorBoundary>
                    </div>
                </div>

                <DisplayContent isVisible={config?.web?.features?.pointsForDrawings}>
                    <div className='row justify-content-center'>
                        <div className='col-12'>
                            <PointsForDrawingsSection {...{ actions, pointsForDrawings }} />
                        </div>
                    </div>
                </DisplayContent>

                <div className='row justify-content-center'>
                    <div className='col-12' id='home-reward-highlights-container'>
                        <ErrorBoundary fallback={<TeleScript line={telescriptHomePromotions?.fallback} />} details={{ user, config }}>
                            <CurrentPromotions
                                {...{ user, config, promotion, cmsSourceFirebase, actions }}
                                telescript={telescriptHomePromotions}
                                mode='show-limited-external-url'
                                options={{
                                    hasCollapser: false,
                                    showNoLimit: true,
                                    variant: 'theme-primary',
                                    jurisdiction: promotionConfig?.jurisdiction,
                                    promotionsLink: '/promotions#my-rewards',
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
