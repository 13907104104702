import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { TeleScript } from 'sg-ui-components';
import PromotionDetails from './PromotionDetails';

const SecondChancePrize = ({ entry, telescript, sweepstake_id, actions, sweep }) => {
    const [show, setShow] = useState(false);

    const showModal = async () => {
        await actions.sweepActions.getSweepDetails({ sweeps_id: sweepstake_id });
        setShow(true);
    };
    const PrizeModal = ({ show, setShow, entry }) => {
        const handleModalClose = () => {
            setShow(false);
        };

        return (
            <Modal
                key={entry.id}
                show={show}
                onHide={handleModalClose}
                backdrop='static'
                keyboard={true}
                size='md'
                dialogClassName='prize-modal'
                contentClassName='prize-modal'
                centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title className='text-center'>
                        <TeleScript line={telescript?.howToClaimTitle}>How to Claim</TeleScript>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <img className='w-100' src={sweep?.sweepDetails?.extra?.image} alt={sweep?.sweepDetails?.extra?.imgAltText} />
                    <h4>{entry?.prize_name}</h4>
                    <TeleScript line={telescript?.prizeModalBody}>
                        <div className='mb-4'>
                            <PromotionDetails sweepsType={entry?.sweeps_type} sweepstakeId={sweepstake_id} />
                        </div>
                        <div className='mx-2'>
                            <p>Please click the above link to view your prize details.</p>
                            <p>
                                Your prize, or additional claim information, will be mailed to the address on your account at the time of the drawing. If you
                                need to update your information, please call: (866) 465-1038.
                            </p>
                            <p>Please allow 6-8 weeks to receive your prize.</p>
                            <p>
                                Questions can be emailed to <a href='mailto:Promotions@molottery.com'>Promotions@molottery.com</a>
                            </p>
                        </div>
                    </TeleScript>
                </Modal.Body>
            </Modal>
        );
    };
    return (
        <div key={entry.id}>
            <Button variant='link' className='theme-btn-link p-0' onClick={() => showModal((entry = { entry }))}>
                <TeleScript line={telescript?.howToClaimButton}>How to Claim</TeleScript>
            </Button>
            <PrizeModal show={show} setShow={setShow} entry={entry} telescript={telescript} />
        </div>
    );
};

export default SecondChancePrize;
