import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import './optins.scss';
import { ContentBox, ContentBoxHead, ContentBoxBody, ContentCollapser, isHash, TeleScript } from 'sg-ui-components';
import { Alert } from 'react-bootstrap';
import { errorMessage } from '../errorMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**********************************************************************
 * Component:  RegistrationOptinForm
 * Purpose:   Used to create the notification selection chckboxes that
 *            are displayed on the Registration page 1.   This grab all
 *            optins available and filters out the ones in the
 *            "registration" category and displays that.
 *
 *            The parent (calling) class defines the callback to
 *            subscribe/unsubcribe as part of the registration
 *            payload.  So does NOT use the subscribe/unsubscribe APIs.
 *
 * Props: -   parentCallback -  callback returned to the parent (registration)
 *
 * APIs used: mrbEnsemble.getAllOptions
 */
const RegistrationOptinForm = ({ actions, optins, parentCallback = '' }) => {
    const [loaded, setLoaded] = useState(false);
    const [registrationOptins, setRegistrationOptins] = useState([]);
    const [registrationCheckedState, setRegistrationCheckedState] = useState(new Map());

    //***********************************************************************************
    // Gets all the optins available (without any player data attached).
    // this could be a large list and will need to be filtered down by category
    const getAllOptins = async () => {
        await actions.optinsActions.getAllOptins();
    };

    //***********************************************************************************
    // Gets all optins when component loads.
    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            getAllOptins();
        }
    }, [loaded]); // end useEffect

    //***********************************************************************************
    // Iterates over the optins list and filters out only the ones in the registration
    // category, as this component should only be used on registration pages.
    //
    // Creates a MAP that keeps track of the checked status of the optin.
    useEffect(() => {
        const myOptins = optins.allOptins;
        if (myOptins) {
            // Registration optins need to have an reg_txt extra field
            const regOptins = myOptins.filter((regOpton) => regOpton?.extra?.reg_txt);

            setRegistrationOptins(regOptins);

            // Map checked values of by optinID.
            let checkedStateById = new Map();

            // using a map of maps here to keep track of the clicked state of the option box.
            // Remapping to confirm with the optin array data requested by backend in /player/register
            // for the front-end we will always assume the subscription type is email.
            regOptins.forEach((optin) => {
                checkedStateById.set(optin.id, {
                    checkedState: true,
                    optin: { type: optin.type, subscriptions: [{ type: 'email' }] },
                });
            });
            setRegistrationCheckedState(new Map(checkedStateById));
        }
    }, [optins]); // end useEffect

    //***********************************************************************************
    // Checked state for this widget managed internally since we won't change the
    // store state until post-registration.
    //
    const handleClick = async (id) => {
        if (registrationCheckedState.size > 0) {
            // Duplicate the Map, change it, and then reset it.
            let newCheckedState = new Map(registrationCheckedState);
            const isChecked = newCheckedState.get(id);
            isChecked.checkedState = !isChecked.checkedState;
            newCheckedState.set(id, isChecked);

            // Maps are a special beast with set state
            setRegistrationCheckedState(new Map(registrationCheckedState));

            // If a parent callback is set, the parent will handle the call to
            // subscribe/unsubscribe a notification.
            if (parentCallback === '') {
                if (newCheckedState.get(id)) {
                    await actions.optinsActions.optinSubscribe(id);
                }
            }
        }
    }; // end handleChange

    return (
        <div className='form-check my-3'>
            {registrationOptins
                ? registrationOptins.map((optin) => {
                    return (
                        <div key={optin.id}>
                            <input
                                type='checkbox'
                                id={optin.id}
                                name={optin.type}
                                className='form-check-input'
                                value={optin.id.toString()}
                                checked={registrationCheckedState.get(optin.id) ? registrationCheckedState.get(optin.id).checkedState : 'true'}
                                onClick={() => handleClick(optin.id)}
                                onChange={parentCallback != '' ? parentCallback(registrationCheckedState) : ''}
                            />
                            <label htmlFor='marketing' className='form-check-label'>
                                <div dangerouslySetInnerHTML={{ __html: optin?.extra?.reg_txt ?? '' }} />
                            </label>
                        </div>
                    );
                })
            : ''}
        </div>
    );
};

const RegistrationOptins = connect(mapStateToProps, mapDispatchToProps)(RegistrationOptinForm);

export { RegistrationOptins };

/**********************************************************************
 * Component:  OptinForm
 * Purpose:   Used to create the notification selection widget/card that
 *            lists all available optins for the user that they can select
 *            categorized by context.
 *
 * Props: - (optional)heading -  title/heading of the card.
 *
 *
 * APIs used:   mrbEnsemble.getOptions - all optins, but with player data
 *              mrbEnsemble.optinSubscribe
 *              mrbEnsemble.optinUnsubscribe
 *
 *  Notes:   Calls the APIs directly, probably should be put into a Store with Redux
 */
const OptinsTemplate = ({ actions, user, optins, telescript }) => {
    const [loaded, setLoaded] = useState(false);
    const [winningNumsOptins, setWinningNumsOptins] = useState([]);
    const [jackpotAlertOptins, setJackpotAlertsOptins] = useState([]);
    const [promotionalOptins, setPromotionalOptins] = useState([]);
    const [scratcherOptins, setScratcherOptins] = useState([]);
    const [registrationOptins, setRegistrationOptins] = useState([]);
    const [checkedState, setCheckedState] = useState(new Map());
    const [error, setError] = useState('');

    //***********************************************************************************
    // Get all the Optins from the API (with player data)
    const getPlayerOptins = async () => {
        // Get the Optins from the Optin Store.
        await actions.optinsActions.getOptins();
    }; // end getAllOptins

    //***********************************************************************************
    // Checks to see if the current optin ID is checked or not.
    const getCheckedState = (id) => {
        let isChecked = false;
        if (checkedState.get(id)) {
            isChecked = checkedState.get(id).checkedState;
        }
        return isChecked;
    };

    //***********************************************************************************
    // Get all the Optins data on load or when the checkedState has changed (and thus when
    // a player has subscribed/unsubscribed from that optin.
    //
    const clearAction = async (actionName) => {
        if (user.player?.actions?.length > 0 && user.player.actions.includes(actionName)) {
            await actions.userActions.actionComplete({ action_name: actionName });
        }
    };

    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            clearAction('review-optins'); //? we need to clear this action on pageload
            getPlayerOptins();
        }
    }, [checkedState, loaded]); // end useEffect

    //***********************************************************************************
    // Goes through the entire optin list and categorizes them by categories.  These
    // categories are managed in Maestro, and thus need to be keep in sync here.   If a new
    // category is added in Maestro - this code should also change.
    //
    // Sets up a new map for each optin to handle checked state.  We also only care about
    // email optins here, so any SMS options are ignored.
    useEffect(() => {
        const myOptins = optins.playerOptins;

        if (optins.error) setError(errorMessage(optins.error));

        if (!myOptins.code && myOptins.length > 0) {
            // Set up/orgamize the different categories of Notifications - NOTE these have to match the contexts
            // set up in Notifier.
            const winningNumberOptins = myOptins.filter((winNumsOptin) => winNumsOptin.context == 'winning-numbers');
            setWinningNumsOptins(winningNumberOptins);

            const jackpotAlertOptins = myOptins.filter((jackpotOptin) => jackpotOptin.context == 'jackpot-alerts');
            setJackpotAlertsOptins(jackpotAlertOptins);

            const promotionalOptins = myOptins.filter((promoOptin) => promoOptin.context == 'lottery-announcements');
            setPromotionalOptins(promotionalOptins);

            const scratcherOptins = myOptins.filter((scratcherOptin) => scratcherOptin.context == 'scratcher-notifications');
            setScratcherOptins(scratcherOptins);

            const registrationOptins = myOptins.filter((registrationOptins) => registrationOptins.context == 'registration');
            setRegistrationOptins(registrationOptins);

            // Map checked values of by optinID.    It will be checked if we have a subscription of type
            // channel type email as desktop for now only cares about email notifications and NOT SMS.
            //
            let checkedStateById = new Map();
            myOptins.forEach((optin) => {
                if (optin.subscription?.length) {
                    // for Desktop we ONLY care about email channels
                    let checked = optin.subscription.find(({ channel }) => channel === 'email');
                    if (checked) {
                        checkedStateById.set(optin.id, { checkedState: true, optin: optin });
                    } else {
                        checkedStateById.set(optin.id, { checkedState: false, optin: optin });
                    }
                } else {
                    checkedStateById.set(optin.id, { checkedState: false, optin: optin });
                }
            });
            setCheckedState(checkedStateById);
        }
    }, [optins]);

    //***********************************************************************************
    // Handler for when a subscription is clicked -  will subscribe or unsubscribe
    // based on clicked state.
    const handleClick = async (id) => {
        // Duplicate the Map, change it, and then reset it.
        const isChecked = getCheckedState(id);

        // Base subscribe/unsubcribe on the OPPOSITE of the checked state -
        // so checkedState of "True" = unsubscribe
        if (!isChecked) {
            await actions.optinsActions.optinSubscribe({ notification_id: id });
        } else {
            const subscriptions = checkedState.get(id).optin.subscription;
            const thisSubscription = subscriptions.find(({ channel }) => channel === 'email');

            await actions.optinsActions.optinUnsubscribe({
                notification_id: id,
                subscription_id: thisSubscription.id,
            });
        }
        // force reload
        setLoaded(false);
    }; // end handleClick

    //***********************************************************************************
    // Helper component for checkbox Optin categories.
    //
    // Note, some optins will have advanced features (TBD) and will be handled by other
    // UI types.
    const OptinsByCategory = ({ optinsByCategory, title }) => {
        return (
            <div className='optin-category'>
                <h2 className='optin-category-title' dangerouslySetInnerHTML={{ __html: title }} />
                {optinsByCategory.map((optin) => {
                    return (
                        <div key={optin.id}>
                            <div className='input-group mx-3'>
                                <label htmlFor={optin.id} className='form-check-label'>
                                    <input
                                        type='radio'
                                        id={optin.id}
                                        name={optin.type}
                                        value={optin.id}
                                        checked={getCheckedState(optin.id)}
                                        onChange={() => handleClick(optin.id)}
                                    />
                                    <span className='mx-3' dangerouslySetInnerHTML={{ __html: optin.extra?.sub_txt ?? '' }} />
                                </label>
                            </div>
                            <div className='input-group mx-3'>
                                <label htmlFor={optin.id} className='form-check-label'>
                                    <input
                                        type='radio'
                                        id={optin.id}
                                        name={optin.type}
                                        value={optin.id}
                                        checked={!getCheckedState(optin.id)}
                                        onChange={() => handleClick(optin.id)}
                                    />
                                    <span className='mx-3' dangerouslySetInnerHTML={{ __html: optin.extra?.unsub_txt ?? 'Unsubscribe from this email' }} />
                                </label>
                            </div>
                            <hr className='optin-separator' />
                        </div>
                    );
                })}
            </div>
        );
    }; // end OptinCategory

    const JackpotAlerts = () => {
        const handleJackpotChange = async (e, optin) => {
            const selectedFilter = e.target.value;
            if (selectedFilter === 'never') {
                const subscriptions = checkedState.get(optin.id).optin.subscription;
                const thisSubscription = subscriptions.find(({ channel }) => channel === 'email');

                await actions.optinsActions.optinUnsubscribe({
                    notification_id: optin.id,
                    subscription_id: thisSubscription.id,
                });
            } else {
                await actions.optinsActions.optinSubscribe({
                    notification_id: optin.id,
                    channel: { type: 'email' },
                    filter: { jackpot: selectedFilter },
                });
            }
            getPlayerOptins();
        };

        return jackpotAlertOptins.map((optin) => {
            let optinFilters = [];
            if (optin.filters) {
                optinFilters = Object.entries(optin.filters);
            }
            let currentValue = '';
            if (optin.subscription?.[0]?.jackpot) {
                currentValue = optin.subscription[0].jackpot;
            }

            return (
                <div className='mx-3 text-justify form-group' key={optin.id}>
                    <label htmlFor={optin.id} className='optin-select-label form-check-label pr-3'>
                        {optin.tag}
                    </label>
                    <select
                        className='optin-select custom-select custom-select-lg mb-3'
                        name={optin.tag}
                        id={optin.id}
                        key={optin.id}
                        defaultValue={currentValue}
                        onChange={(e) => handleJackpotChange(e, optin)}>
                        <option key={`${optin.id} - Never`} value='never'>
                            Never
                        </option>
                        {optinFilters.length &&
                            optinFilters.map((filter) => {
                                return (
                                    <option key={`${optin.id} - ${filter[0]}`} value={filter[0]}>
                                        {filter[1]}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            );
        });
    };

    const hash = 'my-notifications';

    return (
        <ContentBox variant='theme-blue' id={hash} show={isHash(hash) ? 'true' : 'false'}>
            <ContentBoxHead>
                <TeleScript line={telescript?.myNotificationOptionAccordion}> My Notifications Options</TeleScript>

                <ContentCollapser />
            </ContentBoxHead>
            <ContentBoxBody>
                {error ? (
                    <Alert variant='danger'>
                        <FontAwesomeIcon icon='fa-regular fa-circle-xmark' />
                        <div className='alert-text' dangerouslySetInnerHTML={{ __html: error }} />
                    </Alert>
                ) : null}
                <div className='form-step'>
                    <div className='inner-step'>
                        <p>Select notification options below to stay up to date.</p>
                        <OptinsByCategory optinsByCategory={winningNumsOptins} title='<em>My Lottery</em> News &amp; Numbers - Winning Numbers Emails' />
                        {jackpotAlertOptins ? (
                            <div className='my-2'>
                                <h2 className='optin-category-title'>Jackpot Alerts</h2>
                                <JackpotAlerts />
                                <hr className='optin-separator' />
                            </div>
                        ) : (
                            ''
                        )}{' '}
                        {/* End Jackpot Alerts */}
                        <OptinsByCategory optinsByCategory={promotionalOptins} title='Account Notifications' />
                        <OptinsByCategory optinsByCategory={scratcherOptins} title='New Scratchers&reg; Notifications' />
                        <OptinsByCategory optinsByCategory={registrationOptins} title='Marketing Communications' />
                        <p>If you do not receive e-mail from us, check your spam settings.</p>
                        <p>NOTE: Preferences are updated automatically.</p>
                    </div>
                </div>
            </ContentBoxBody>
        </ContentBox>
    );
}; // end OptinAccordion

const OptinAccordian = connect(mapStateToProps, mapDispatchToProps)(OptinsTemplate);

export { OptinAccordian };
