/**********************************************************************
 * Component: validateTicketResponse
 * Purpose:   Helper method that merges responses from Bonusing and
 *            Ticket Services and returns error codes to the rendering
 *            templates to give the user more clear messaging on why
 *            their ticket might have been rejected.
 *
 *            This is used in all ticket-stores (scratch, draw, igt)
 *            although the logic in each juristiction might be a bit
 *            different.
 *
 *            NOTE:  This code should be moved to a backend service!
 *
 * Props: - response - the response from the enterTickets API.
 *
 * APIs used: none.
 */
const validateTicketResponse = (response) => {
    if (response.success && response?.entry?.TicketDetails?.IsPromotionEligible === 'No') {
        response.success = false;
        response.message = response?.entry?.TicketDetails?.TicketStatus;
    }
    if (response?.entry?.Bonus && ((Array.isArray(response?.entry?.Bonus) && response?.entry?.Bonus.length === 0) || response?.entry?.Bonus === null)) {
        response.success = false;
        response.message = '_NOT_AVAILABLE';
    }

    return response;
};

export default validateTicketResponse;
