import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../../Store';

import { IfMobile } from 'sg-ui-components';

const ScannerTemplate = ({ buttonText = 'Scan Ticket', ticket = { success: false }, actions }) => {
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            // actions.drawTicketActions.reset();
            window.open('post_claim');
        }
    };

    const scanCallback = async (infoObject) => {
        console.log('finished scan: ', infoObject);

        await actions.drawTicketActions.enterDrawTicket({
            ticket1_1_1: infoObject.result,
        });
    };

    // fire the submission
    const handleSubmit = async (event) => {
        setDisabled(true);
        event.preventDefault();

        console.log('scanning');
        // window.MdiNativeShell =
        //     typeof window.MdiNativeShell === 'undefined'
        //         ? (function (barcode) {
        //               return {
        //                   scanBarcode: function (fn) {
        //                       fn({ format: 'DATA_MATRIX', result: barcode });
        //                   },
        //               };
        //           })('441002530000484294186417153002')
        //         : window.MdiNativeShell;

        const mdiIsNativeShell = () => {
            // an array (true) or null (false) is returned and cast to boolean
            return !!window.navigator.userAgent.match(/(Mdi Native Shell)/);
        };

        if (mdiIsNativeShell()) {
            await window.MdiNativeShell.scanBarcode(scanCallback);
        } else {
            console.warn('No scanner');
        }

        setDisabled(false);
    };

    return (
        <IfMobile>
            <div className='text-center'>
                <button disabled={disabled} onClick={handleSubmit} type='button' className='scan-tickets-btn'>
                    {buttonText}
                </button>
            </div>
        </IfMobile>
    );
};

const Scanner = connect(mapStateToProps, mapDispatchToProps)(ScannerTemplate);

export default Scanner;
