import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCMSObject, SweepstakeDetails, DrawingGroupTable } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import WinnerLinkWrapper from '../Components/WinnerLinkWrapper';

const SecondChancePromotionPage = ({ user, config, sweep, cmsSourceFirebase, actions, match, loading }) => {
    const [promotionName, setPromotionName] = useState('Promotion Details');

    const telescriptSweepstakeDetails = getCMSObject('data.components.teleScripts.sweepstakeDetails.jsonBlock');
    const telescriptDrawingGroupTable = getCMSObject('data.components.teleScripts.drawingGroupTable.jsonBlock');

    const DrawingGroupTableWrapper = ({ drawingGroup, loggedIn }) => {
        const todayTimestamp = new Date().getTime();

        // Create a new array with the updated drawings
        const updatedDrawings = drawingGroup.drawings.map(function (item) {
            // If quantum_end is less than today's date, return a new object with certified set to true
            if (item.quantum_end < todayTimestamp) {
                return { ...item, certified: true };
            } else {
                return item;
            }
        });

        // Create a new drawingGroup object with the updated drawings
        const updatedDrawingGroup = { ...drawingGroup, drawings: updatedDrawings };
        return (
            <DrawingGroupTable drawingGroup={updatedDrawingGroup} loggedIn={loggedIn} telescript={telescriptDrawingGroupTable} WinnerLink={WinnerLinkWrapper} />
        );
    };

    return (
        <Layout
            {...{
                user,
                sweep,
                config,
                cmsSourceFirebase,
                actions,
                match,
                configReplacements: [
                    {
                        find: '{{PROMOTION_NAME}}',
                        replace: promotionName ?? '',
                    },
                ],
            }}>
            <SweepstakeDetails
                {...{ user, sweep: sweep.sweepDetails, actions, loading }}
                telescript={telescriptSweepstakeDetails}
                DrawingGroupTable={DrawingGroupTableWrapper}
                setPageTitle={setPromotionName}
            />
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondChancePromotionPage);
