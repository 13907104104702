import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga4';
import { camelCase } from 'lodash';
import {
    IfNotMobile,
    CMSContent,
    StyleOverrides,
    scrollToHashElement,
    ErrorBoundary,
    TeleScript,
    getCMSObject,
    FeatureLockout,
    TitleBreadcrumb,
    getInterpolatedPageData,
} from 'sg-ui-components';
import Footer from './Layouts/Footer';
import Header from './Layouts/Header';
import IdleTimeout from './IdleTimeout';
import PointsDashboard from './Dashboard/PointsDashboard';
import { testing, seoDefaultTitle, routes } from '../promotion_config';
import { Container } from 'react-bootstrap';
import LoggedInOnlyPages from '../Components/LoggedInOnlyPages';
import LoadingIndicator from './LoadingIndicator';
import UserLockout from '../utils/user_lockout';

const Layout = ({ user, config, cmsSourceFirebase, actions, children, match, configReplacements }) => {
    const path = match?.path;
    const pageData = getInterpolatedPageData(routes, match, configReplacements);
    const errorBoundaryFallback = getCMSObject('data.messages.pageErrorMessages.jsonBlock')?.[`${camelCase(path) || 'home'}Fallback`];

    const canDisplayTitleBreadcrumb = () => {
        if (user?.loggedIn) {
            return true;
        }

        if (pageData?.userLockout) {
            return false;
        }

        return !!pageData;
    };

    //? Note: Frontend config will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function configStateActions() {
            await actions.configActions.getEnvironmentConfig();
        }

        if (!config?.config?.firebase) {
            configStateActions();
        }
    }, []);

    //? Note: Firebase CMS webContent and webMenu will be requested and stored on first pageload / refresh
    useEffect(() => {
        async function pageStateActions() {
            // Pull CMS data to local storage
            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webConfig',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webContent',
            });

            await actions.cmsSourceFirebaseActions.storeAllData({
                config: testing?.firebase ?? config.config.firebase,
                docName: 'webMenu',
            });

            await actions.cmsSourceFirebaseActions.setLoaded(true);

            // Set config store
            const webConfigData = getCMSObject('data', 'webConfig') ?? {};
            await actions.configActions.setWebConfig(webConfigData);

            const siteConfigData = { routes: routes };
            await actions.configActions.setSiteConfig(siteConfigData);

            await actions.configActions.setLoaded(true);

            scrollToHashElement();
        }

        if (config?.config?.firebase && !cmsSourceFirebase?.loaded) {
            pageStateActions();
        }
    }, [config]);

    //? Note: User datas and dashboard will be requested on every page, to revalidate tokens and displaying current points
    useEffect(() => {
        async function playerStateActions() {
            await actions.userActions.getPlayerData();

            if (user.loggedIn && user.player.actions.length === 0) {
                await actions.userActions.getDashboard();
                await actions.revealTokenActions.getRevealToken();
            }
            scrollToHashElement();
        }

        playerStateActions();
    }, [user?.loggedIn]);

    // Google Tag Manager initialization
    if (config?.config?.gtm_id) {
        ReactGA.initialize(config?.config?.gtm_id);
    }

    const isLoaded = config?.loaded && cmsSourceFirebase.loaded;
    //? Redirect players to login page if the page is logged in only page and the players are not logged in
    if (pageData?.loggedInOnly && !user.loggedIn) {
        return <LoggedInOnlyPages />;
    }

    if (!isLoaded) {
        return (
            <div className='d-flex justify-content-center my-5'>
                <LoadingIndicator />
            </div>
        );
    }
    return (
        <FeatureLockout config={config} page={pageData} user={user} options={{ redirectPath: pageData?.featureDisabledRedirectPath }}>
            <Helmet defaultTitle={seoDefaultTitle} titleTemplate={`%s | ${seoDefaultTitle}`}>
                <title>{pageData?.title}</title>
            </Helmet>
            <div className={`content-root ${pageData?.className}`}>
                <div className='galaxy-fold-open-your-device d-none'></div>

                <IfNotMobile>
                    <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-top' noticePlacement='web-notice-top-fixed' />
                    <CMSContent localStorageObject='webContent' contentPath='data.webNotices' noticePlacement='web-notice-top' />
                    <Header />
                    <CMSContent localStorageObject='webContent' contentPath='data.webNotices' noticePlacement='web-notice-below-header' />
                </IfNotMobile>
                <UserLockout currentSection={pageData?.className} disableRedirect={pageData?.className}>
                    <div>{pageData.className !== 'account-confirm' && <PointsDashboard />}</div>
                    <ErrorBoundary fallback={<TeleScript line={errorBoundaryFallback} />} details={{ pageData, user, config }}>
                        <div className='layout container'>
                            <div className='main row'>
                                <div className='page-content w-100'>
                                    <Container>
                                        {canDisplayTitleBreadcrumb() && <TitleBreadcrumb pageData={pageData} />}
                                        {children}
                                    </Container>
                                </div>
                            </div>
                        </div>
                    </ErrorBoundary>
                </UserLockout>
            </div>
            <IfNotMobile>
                <CMSContent localStorageObject='webContent' contentPath='data.webNotices' className='fixed-bottom' noticePlacement='web-notice-bottom-fixed' />
                <Footer />
            </IfNotMobile>
            <IdleTimeout />
            <StyleOverrides cmsSourceFirebase={cmsSourceFirebase} />
        </FeatureLockout>
    );
};

export default Layout;
